import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { animationSlideUpDown, transition } from '../../animations';

const ParagraphContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  vertical-align: center;
`

const Paragraph = styled.p`
  
  font-size: 2em;
  margin: 6em;
  max-width: 75%;
  max-height: 75%;
`
const Title = styled.h1`
  margin: 0.5em 0 0 3em;
  max-width: 75%;
  font-size: 60px;
`

export function Presentation(props) {
    return (
        <motion.div
            key='Presentation'
            initial='slide'
            animate='center'
            custom={props.direction}
            variants={animationSlideUpDown}
            transition={transition}
        >
            <Title>Le projet</Title>
            <ParagraphContainer>
                <Paragraph>
                    Chaque jour des attaques informatiques de plus en plus sophistiquées et ingénieuses
                    ont lieu. Pourtant le grand public n’est que peu renseigné sur le déroulement de ces
                    attaques, la complexité de celles-ci, les cibles de ces attaques ainsi que les moyens et
                    outils permettant de se protéger face aux cyberattaques. C’est dans ce contexte que nous
                    avons choisi de travailler sur le sujet de la sensibilisation informatique aux cyberattaques
                    et plus précisément sur la façon dont celles-ci étaient représentées au grand public en particulier dans les films et les séries.
                </Paragraph>
            </ParagraphContainer>
        </motion.div>
    )
}