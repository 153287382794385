import React from "react";
import styled from "styled-components";

const MainTitle = styled.h1`
  max-width: 75%;
  font-size: 60px;
  left: 0;
  line-height: 60px;
  margin-top: 0px;
  margin-left: 25%;
  position: absolute;
  text-align: left;
  top: 40%;
  display: flex;
  flex-direction: column;
`

const TitleBit = styled.span`
  z-index: 3;
  opacity: 0;
  animation: anim-lineUp 2s ease-out ${props => props.delay} forwards;
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateX(80%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
`

const Bg = styled.div`
  background-color: lightsteelblue;
  opacity: 1;
  right: 0;
  top: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 0;
`

export function Home(props) {
    return (
        <div>
            <Bg>
                <MainTitle> <TitleBit delay="0.1s">Est-ce la</TitleBit> <TitleBit delay="0.5s">vraie vie ?</TitleBit> </MainTitle>
            </Bg>
        </div>
    );
}

