import React from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { animationSlideUpDown, transition } from '../../animations';

const ParagraphContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  vertical-align: center;
`

const Paragraph = styled.p`
  font-size: 2em;
  margin: 6em;
  max-width: 75%;
  max-height: 75%;
`
const Title = styled.h1`
  margin: 0.5em 0 0 3em;
  max-width: 75%;
  font-size: 60px;
`

export function MentionsLegales(props) {
    return (
        <motion.div
            key='Mentions Legales'
            initial='slide'
            animate='center'
            custom={props.direction}
            variants={animationSlideUpDown}
            transition={transition}
        >
            <Title>Mentions légales</Title>
            <ParagraphContainer>
                <Paragraph>
                    Esmail, S. (Créateur). (2015-2019). <i>Mr. Robot</i> saison 1 épisode 2 (41:30).<br/>
                    Willimon, B. (Créateur). (2013-2018). <i>House of Cards</i> saison 2 épisode 3 (11:50).
                </Paragraph>
            </ParagraphContainer>
        </motion.div>
    )
}