import './App.css';
import Menu from "./Components/Menu";
import {Home} from "./Components/Pages/Home";
import Slider from "./Components/Pages/Slider";
import {Presentation} from "./Components/Pages/Presentation";
import {Article} from "./Components/Pages/Article";
import Trombi from "./Components/Pages/Trombi";
import {MentionsLegales} from "./Components/Pages/MentionsLegales";
import NavigationArrow from "./Components/NavigationArrow";
import { Switch, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import React, {useState} from "react";

function App() {
  let location = useLocation();
  const [direction, setDirection] = useState(1);
  const handleClickNavArrow = (up) => {

    if (up) {
      setDirection(1);
    }
    else {
      setDirection(-1);
    }
  }

  const ArrowContainer = styled.div`
    z-index: 10;
    width: 10%;
    height: 10%;
    position: fixed;
    bottom: 0%;
    margin-left: 45%;
    margin-top: 90vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  `

  return (
      <div className={"pages"}>
        <Menu/>
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route path={"/"} exact render={() => <Home />} />
              <Route path={"/Presentation"} exact render={() => <Presentation direction={direction}/>}/>
              <Route exact path={"/Carrousel"} render={() => <Slider direction={direction}/>}/>
              <Route exact path={"/Trombi"} render={() => <Trombi direction={direction}/>}/>
              <Route exact path={"/MentionsLegales"} render={() => <MentionsLegales direction={direction}/>}/>
              <Route path={"/articles/:articleId"} render={() => <Article/>}/>}/>
            </Switch>
            <ArrowContainer>
              <NavigationArrow up={false} clickHandler={handleClickNavArrow}/>
              <NavigationArrow up={true} clickHandler={handleClickNavArrow}/>
            </ArrowContainer>
          </AnimatePresence>
      </div>

    );
}

export default App;
