import React from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import NavigationIconDown from "@mui/icons-material/ArrowDropDown";
import NavigationIconUp from "@mui/icons-material/ArrowDropUp";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';

function modulo(a,b) {
    let res = a % b;
    if (a < 0) {
        res = a + b;
    }
    return res;
}

export default function NavigationArrow(props) {


    const pages = ["/", "/Presentation", "/Carrousel", "/Trombi", "/MentionsLegales"];

    let arrow = <span></span>;

    const location = useLocation().pathname;
    if(/articles\/*/.test(location) === false) {


        let actualPage = pages[modulo(pages.indexOf(location) + 1, pages.length)];

        if (props.up) {
            actualPage = pages[modulo(pages.indexOf(location) - 1, pages.length)];
        }


        let navIc = <Box
            sx={{
                "& > :not(style)": {
                    position: 'relative',
                    zIndex: 1000,
                    m: '4px'
                }
            }}>
            <Fab size="large" variant="circular">
                <NavigationIconDown sx={{fontSize: 30, mr: 0}}/>
            </Fab>
        </Box>

        if (props.up) {
            navIc = <Box
                sx={{
                    "& > :not(style)": {
                        position: 'relative',
                        zIndex: 1000,
                        m: '4px'
                    }
                }}>
                <Fab size="large" variant="circular">
                    <NavigationIconUp sx={{fontSize: 30, mr: 0}}/>
                </Fab>
            </Box>
        }
        arrow = <NavLink className={'navArrow'} onClick={() => props.clickHandler(props.up)} to={actualPage}>
            {navIc}
        </NavLink>
    }

    return arrow;
}
