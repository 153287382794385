import React, {useRef} from 'react';
import "./Slider.css"
/*
import useSlider from "../../hooks/useSlider";
*/
import {NavLink} from "react-router-dom";
import { motion } from 'framer-motion';
import { animationSlideUpDown, transition } from '../../animations';
import articles from "../../articles/articles_content";


/*
function NavArrows(props = false) {

}
*/

const Slider = (props) => {

    const slideImage = useRef(null)
    const slideText = useRef(null)
    /*
    const { goToPreviousSlide, goToNextSlide } = useSlider(slideImage, slideText, images)
    */

    return (
        <motion.div className="slider" ref={slideImage}
            key='Carrousel'
            initial='slide'
            animate='center'
            custom={props.direction}
            variants={animationSlideUpDown}
            transition={transition}
        >
            <div className="slider--content">
                <div className="slider--feature">
                    <h1 className="feature--title">{articles[1].title}</h1>
                    <p ref={slideText} className="feature--text">
                        {articles[1].abstract}
                    </p>
                    <NavLink to={"/articles/"+articles[1].id}>
                        <button className="feature__btn" >Voir l'article</button>
                    </NavLink>
                </div>
            </div>
        </motion.div>
    );
}

export default Slider;