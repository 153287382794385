import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";


const COLORS={
    primaryDark: "#AECAF4",
    primaryLight: "#e0e0e0",
    primaryButton: "#e0e0e0"
}

const MenuLabel = styled.label`
  background-color: ${COLORS.primaryButton};
  position: fixed;
  top: 4rem;
  left: 4rem;
  border-radius: 50%;
  height: 7rem;
  width: 7rem;
  cursor:pointer;
  z-index:1000;
  boxshadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
  text-align:  center;
`

const NavBackground = styled.div`
  background-color: black;
  position: fixed;
  top: 6.5rem;
  left: 6.5rem;
  background-image: radial-gradient(
          ${COLORS.primaryDark},
          ${COLORS.primaryLight}
  );
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  z-index: 600;
  transform: ${(props) => (props.clicked ? "scale(80)" : "scale(0)")};
  transition: transform 0.8s;
  transition: transform 0.8s;
`

const Icon = styled.span`
  position: relative;
  top: 0;
  background-color: ${(props) => (props.clicked ? "transparent" : "black")};
  width: 3rem;
  height: 0.2rem;
  display: inline-block;
  margin-top: 3.5rem;
  transition: all 0.4s;
  
  
  &::before, &::after {
    content: "";
    background-color: black;
    width: 3rem;
    height: 0.2rem;
    display: inline-block;
    position: absolute;
    left: 0;
    transition:all 0.4s;
    top: 0;
  }
  &::before {
    top: ${props => (props.clicked? "0rem":"-0.8rem")};
    transform: ${props => (props.clicked? "rotate(135deg)":"rotate(0)")};
  }
  
  &::after {
    top: ${props => (props.clicked? "0rem":"0.8rem")};
    transform: ${props => (props.clicked? "rotate(-135deg)":"rotate(0)")};
  }
`

const Navigation = styled.nav`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 600;
  width: 100%;
  
  display: ${(props => props.clicked? "" : "none")};
  width: ${(props => props.clicked? "100%" : "0")};
  opacity: ${(props => props.clicked? "1" : "0")};

  transition: width 0.8s, opacity 0.8s;
`

const List = styled.ul`
  height: initial;
  position: absolute;
  list-style: none;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50%;
  
`

const ItemLink = styled(NavLink)`
  display:inline-block;
  font-size:3rem;
  font-weight: 300;
  text-decoration: none;
  color: black;
  padding:1rem 2rem;
  background-image: linear-gradient(
          120deg,
          transparent 0%,
          transparent 50%, 
          #fff 50%
  );
  
  background-size: 230%;
  transition: all 0.4s;
  
  &:hover, &:active {
    background-position: 100%;
    color: ${COLORS.primaryDark};
    transform: translateX(1rem);
  }
`

export default function Menu() {
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click)

    return (
        <>
            <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                <Icon clicked={click}> &nbsp; </Icon>
            </MenuLabel>
            <NavBackground clicked={click}> &nbsp; </NavBackground>

            <Navigation clicked={click} onClick={handleClick}>
                <List>
                    <li>

                        <ItemLink onClick={handleClick} to="/">
                            Accueil
                        </ItemLink>
                    </li>
                    <li>
                        <ItemLink onClick={handleClick} to="/Presentation">
                            Présentation
                        </ItemLink>
                    </li>
                    <li>
                        <ItemLink onClick={handleClick} to="/Carrousel">
                            Articles
                        </ItemLink>
                    </li>
                    <li>
                        <ItemLink onClick={handleClick} to="/Trombi">
                            Qui sommes-nous?
                        </ItemLink>
                    </li>
                    <li>
                        <ItemLink onClick={handleClick} to="/MentionsLegales">
                            Mentions Légales
                        </ItemLink>
                    </li>
                </List>
            </Navigation>
        </>
    )
}