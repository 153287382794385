const directionOffset = window.screen.availHeight;

export const animationSlideUpDown = {
    slide: direction => ({
        y: direction < 0 ? directionOffset : -directionOffset,
        opacity: 0
    }),
    center: {
        zIndex: 1,
        y: 0,
        opacity: 1
    },
};

export const transition = {
    duration: 1,
    delay: 0.1
};
