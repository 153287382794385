import React from 'react';
import styled from "styled-components";
import './TrombiComponent.css'

export default function TrombiComponent(props) {

    const P = styled.p `
      background: #82a3d4;
      padding: 10px 15px;
      color: #fff;
      font-size: 3em;
      width: 100%;
      position: relative;
      z-index: 10;
      bottom: 85%;
      left: ${(props.right? "none" : "50%")};
      right: ${(props.right? "50%" : "none")};
      border-radius: 3px;
      transition: all 0.5s ease;
      opacity: 0;
    `

    const Person = styled.div `
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 280px;
      &:hover P {
        opacity: 1;
        left: ${(props.right? "none" : "100%")};
        right: ${(props.right? "100%" : "none")};
      }
    `

    return (
            <Person className="person">
                <div className="container">
                    <div className="container-inner">
                        <img
                            className="circle"
                            src={props.bgImg}
                            alt="Background"
                        />
                        <img
                            className="img img1"
                            src={props.portraitImg}
                            alt="Profile"
                        />
                    </div>
                    <P className="pic-caption">
                        <h4>{props.presentation.name}</h4>
                        {props.presentation.filiere}<br/>
                        {props.presentation.job}
                    </P>
                </div>
            </Person>
    )
}
