import bg from "./pic_bg.jpg"
import lilian from "./lilian.png"
import margot from "./margot.png"
import thib from "./thibaud.png"
import abdel from "./abdel.png"

const portrait = [
    {
        bg: bg,
        id: abdel,
        text: {
            name: 'Abdellah OUMLIL',
            filiere: 'Etudiant en deuxième année Filière 5 - Réseau et sécurité',
            job: 'Développement du site web'
        }
    },
    {
        bg: bg,
        id: margot,
        text: {
            name: 'Margot NOYELLE',
            filiere: 'Etudiant en deuxième année Filière 5 - Réseau et sécurité',
            job: 'Développement du site web'
        }
    },
    {
        bg: bg,
        id: lilian,
        text: {
            name: 'Lilian HIAULT',
            filiere: 'Etudiant en deuxième année Filière 5 - Réseau et sécurité',
            job: 'Développement de la partie attaque'
        }
    },
    {
        bg: bg,
        id: thib,
        text: {
            name: 'Thibaud LEROY',
            filiere: 'Etudiant en deuxième année Filière 5 - Réseau et sécurité',
            job: 'Développement de la partie attaque'
        }
    }
]

export default portrait