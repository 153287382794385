import React from 'react';
import portrait  from "../../images/portrait";
import TrombiComponent from "../Single_Component/TrombiComponent";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { animationSlideUpDown, transition } from '../../animations';


export default function Trombi(props) {


    const ItemsLayout = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      column-gap: 20%;
      flex-wrap: wrap;
      width: 75%;
      background-color: lightsteelblue;
      position: relative;
      top: 6em;
      margin: auto;
    `


    return (
        <motion.div
            key='Home'
            initial='slide'
            animate='center'
            custom={props.direction}
            variants={animationSlideUpDown}
            transition={transition}
        >
            <ItemsLayout>
                <TrombiComponent
                    right = {false}
                    bgImg = {portrait[0].bg}
                    portraitImg = {portrait[0].id}
                    presentation = {portrait[0].text}
                >
                </TrombiComponent>
                <TrombiComponent
                    right = {true}
                    bgImg = {portrait[1].bg}
                    portraitImg = {portrait[1].id}
                    presentation = {portrait[1].text}
                >
                </TrombiComponent>
                <TrombiComponent
                    right = {false}
                    bgImg = {portrait[2].bg}
                    portraitImg = {portrait[2].id}
                    presentation = {portrait[2].text}
                >
                </TrombiComponent>
                <TrombiComponent
                    right = {true}
                    bgImg = {portrait[3].bg}
                    portraitImg = {portrait[3].id}
                    presentation = {portrait[3].text}
                >
                </TrombiComponent>
            </ItemsLayout>
        </motion.div>
    )
}