import React from "react";
import {useParams} from "react-router-dom";
import articles from "../../articles/articles_content";
import "./Article.css";

function RenderText(props) {
    let render;
    if (props.type === "code") {
        render = <div className="codeContainer">
            {props.text.map(line =>
                <span className="codeLine">{"> "+line}</span>
            )}
        </div>
    }
    else if (props.type === "paragraph") {
        render =
            props.text.map(line =>
                    <p>{line}</p>
            )

    }
    else if (props.type === "list") {
        render = <ul>
            {props.text.map(item =>
                <li>{item}</li>
            )}
        </ul>
    }
    return render;
}

export function Article(props) {

    const {articleId} = useParams();

    return (

        <div className="articleContainer">
            <h1>{articles[articleId].title}</h1>
            <h3>Fiction: </h3>
            <div className="videoContainer">

                {articles[articleId].extracts.map( url =>
                    <iframe width="500px" height="280px" src={url} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
                )

                }
            </div>

            <h3>Réalité:</h3>
            <div className="videoContainer">
                    <iframe width="500px" height="280px" src={articles[articleId].video} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                    </iframe>
            </div>

            <div className="paragraphContainer">
                {
                    articles[articleId].text.map(p =>
                        <RenderText type={p.type} text={p.text}/>
                    )
                }
            </div>
        </div>

    )
}